let europeImageData = [
  "images/europe/brixton.png",
  "images/europe/chef.png",
  "images/europe/crazy.png",
  "images/europe/crete.png",
  "images/europe/josh.png",
  "images/europe/mile.png",
  "images/europe/palermo.png",
  "images/europe/flare.png",
];

export default europeImageData;
