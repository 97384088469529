let saImageData = [
  "images/sa/dance.png",
  "images/sa/parade.png",
  "images/sa/oldman.png",
  "images/sa/trumpet.png",
  "images/sa/seller.png",
  "images/sa/geezer.png",
  "images/sa/train.png",
  "images/sa/girls.png",
];

export default saImageData;
