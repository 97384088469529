import { ReactComponent as Icon } from "../icons/stars.svg";

const StarsSvg = () => {
  return (
    <div>
      <Icon />
    </div>
  );
};

export default StarsSvg;
