let asiaImageData = [
  "images/asia/seoul.png",
  "images/asia/umbrella.png",
  "images/asia/girls.png",
  "images/asia/oldlady.png",
  "images/asia/pointy.png",
  "images/asia/tracks.png",
  "images/asia/guy.png",
  "images/asia/mother.png",
];

export default asiaImageData;
